import { NavLink } from "react-router-dom";
import "./RegisterWarranty.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const RegisterHead = () => (

    <Helmet>
        <title>Register Warranty</title>
        <meta name="description" content="Register your BLDC ceiling fan warranty with Colorbot to ensure protection and support for your products and enjoy long-lasting cooling operation." />
        <link rel="canonical" href="https://colorbot.in/warranty-registration" />

        <meta property="og:title" content="Register Warranty" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/warranty-registration" />
        <meta property="og:description" content="Register your BLDC ceiling fan warranty with Colorbot to ensure protection and support for your products and enjoy long-lasting cooling operation." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/register-warranty-banner.jpg" />
    </Helmet>
);

function RegisterWarranty() {
    const fileInputRef = useRef(null);
    let currentDate = new Date();

    // Subtract 30 days from the current date
    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);

    // Format the warranty date as a string (YYYY-MM-DD)
    let warrantyDate = thirtyDaysAgo.toISOString().split('T')[0];
    const [filename, setfilename] = useState("")
    const handleButtonClick = () => {
        setfilename("")
        fileInputRef.current.click();
    };
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);

    const [serial, setSerial] = useState('');
    const [modal, setModal] = useState('');
    const [platform, setPlatform] = useState('');
    const [date, setDate] = useState('');
    const [invoice, setInvoice] = useState('');
    const [itemNo, setItemNo] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Do something with the selected file (e.g., upload, read contents, etc.)

        setfilename(selectedFile.name)
    };
    const [lgShow, setLgShow] = useState(false);
    const [warrantypolicy, setwarrantypolicy] = useState(false)
    const [active, setactive] = useState(false)
    const [lgShow1, setLgShow1] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Register Warranty - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Register Warranty - Colorbot"; // Reset the title when component unmounts
        };
    }, []);


    function warrantypop() {
        setLgShow(true)
    }
    const h3RefRegister = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isH3Visible, setIsH3Visible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefRegister.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {

                setIsH3Visible(true);

            } else {
                //   setIsH3Visible(false);
            }
            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        // const title = h3RefRegister.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
    }, [scrollPosition])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');


    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber(inputValue);

    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

    };



    const handleSubmit = async () => {


        const phoneRegex = /^\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (name === "") {
            toast.error("Please enter your name.")
        } else if (phoneNumber === "") {
            toast.error("Please enter your phone number.")
        } else if (!phoneRegex.test(phoneNumber)) {
            toast.error("Please enter a valid 10 digit Phone Number")
        } else if (email === "") {
            toast.error("Please enter your email address.")
        } else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid email address.")

        } else if (serial === "") {
            toast.error("Please enter your product order Id.")
        } else if (modal === "") {
            toast.error("Please select your product modal.")
        } else if (itemNo === "") {
            toast.error("Please enter your product item no.")

        } else if (platform === "") {
            toast.error("Please select your product platform.")
        } else if (date === "") {
            toast.error("Please select your product purchase date.")
        } else if (filename === "") {
            toast.error("Please upload your product invoice.")
        } else if (warrantypolicy === false) {
            toast.error("Please accept the warranty policy.")
        }

        else {
            setactive(true)

            try {

                const formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('phone', phoneNumber);
                formData.append('modal', modal);
                formData.append('orderid', serial);
                formData.append('itemno', itemNo);
                formData.append('date', date);
                formData.append('platform', platform);
                formData.append('file', file);

                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotRegisterWarranty`, {
                    method: 'POST',
                    body: formData
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {
                        setactive(false)
                        setLgShow1(true)
                        toast.success(response.message)
                        setName("")
                        setEmail("")
                        setPhoneNumber("")
                        setModal("")
                        setSerial("")
                        setwarrantypolicy(false)
                        setPlatform("")
                        setItemNo("")
                        setDate("")
                        setInvoice("")
                        setfilename("")

                    } else {
                        setactive(false)
                        toast.error(response.error)
                    }
                })
            } catch (error) {
                console.log(error)
            }

        }


    };
    const today = new Date().toISOString().split('T')[0];
    return (
        <>

            <section className='mt-5 pt-4'>
                <RegisterHead />
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{
                        borderRadius: "10px"
                    }}

                    centered
                    show={lgShow1}

                    onHide={() => setLgShow1(false)}

                >
                    <Modal.Header style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/successicon.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Thank You! Your Warranty has been registered successfully.</p>
                            </div>
                            <div className="logoutpop button">
                                <button className="btn btncancellog" style={{
                                    background: "#242933",
                                    padding: "6px 47px",
                                    color: "white",
                                    borderRadius: "8px"
                                }} onClick={() => setLgShow1(false)}>
                                    Ok
                                </button>

                            </div>







                        </div>


                    </Modal.Body>
                </Modal>



                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 padd-neg">
                            <div className="banner-img">
                                {/* <h3 className="banner-text">Register Warranty</h3> */}
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/register-warranty-banner.jpg"}
                                    width={"100vw"}
                                    className="d-block w-100 img-fluid" alt="Register warranty"
                                    effect="blur"
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5">
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-7 ">
                            <div className="registertitle">
                                <h1 className={`border-bottom-animation h3hoverbefore ${isH3Visible ? 'active' : ''}`} ref={h3RefRegister}>Warranty Form</h1>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-usregister mt-4 p-5">
                                <div className="text-center w-100">
                                    <p className="registersubtitle">Register to unlock a longer safety coverage for your product.

                                        Get <b>2 years</b> of additional warranty by Colorbot.  </p>
                                </div>
                                <input type="text" placeholder="Name*" className="form-control form-control-lg mt-4" value={name} onChange={(e) => {
                                    setName(e.target.value)
                                }} />
                                <input
                                    type="text"
                                    placeholder="Phone*"
                                    className="form-control form-control-lg mt-3"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    minLength={10}
                                    maxLength={10}

                                />

                                <input
                                    type="email"
                                    placeholder="Email*"
                                    className="form-control form-control-lg mt-3"
                                    value={email}
                                    onChange={handleEmailChange}

                                />
                                <input type="text" placeholder="Order ID*" className="form-control form-control-lg mt-3" value={serial} onChange={(e) => {
                                    setSerial(e.target.value)
                                }} />
                                <select className="form-control form-control-lg mt-3" style={{
                                    padding: "8px 25px",
                                    color: "#212121",

                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal"

                                }} value={modal} onChange={(e) => {
                                    setModal(e.target.value)

                                }}>
                                    <option value={""}>Product Modal</option>
                                    <option value={"Helix"}>Helix</option>
                                    <option value={"Stella"}>Stella</option>
                                    <option value={"Prizma"}>Prizma</option>
                                    <option value={"Knoxx"}>Knoxx</option>
                                    <option value={"Knoxx-Quad"}>Knoxx Quad</option>
                                    <option value={"Stella-Quad"}>Stella Quad</option>

                                </select>
                                <input type="text" placeholder="Item No*" className="form-control form-control-lg mt-3" value={itemNo} onChange={(e) => {
                                    setItemNo(e.target.value)
                                }} />
                                <select className="form-control form-control-lg mt-3" style={{
                                    padding: "8px 25px",
                                    color: "#212121",

                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal"
                                }} value={platform} onChange={(e) => {
                                    setPlatform(e.target.value)

                                }}>
                                    <option value={""}>Platform</option>
                                    <option value={"ColorBot"}>ColorBot Website</option>
                                    <option value={"Amazon"}>Amazon</option>
                                    <option value={"Flipkart"}>Flipkart</option>
                                </select>

                                <label className="mt-3" >Date of Purchase*</label>

                                <input type="date" className="form-control form-control-lg mt-1" min={warrantyDate} max={today} value={date} onChange={(e) => {
                                    setDate(e.target.value)
                                }} />
                                <label className="mt-3">Upload Invoice</label>
                                <button className="btn-primary btn-upload mt-2" onClick={handleButtonClick}>
                                    Choose File
                                </button> {filename}
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />

                                <div className="form-check mt-3">
                                    <input type="checkbox" className="form-check-input" id="registerexampleCheck1" checked={warrantypolicy} onChange={() => {
                                        setwarrantypolicy(!warrantypolicy)
                                    }} />
                                    <label className="form-check-label" htmlFor="registerexampleCheck1">I have read and accepted the <NavLink to="/services-policies" target="_blank" className="link" >Warranty Policy</NavLink>.</label>
                                </div>
                                <button className="btn btn-lg btn-primary mt-4 mb-4 contactsubmit" disabled={active} onClick={() => {
                                    handleSubmit()
                                }}>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />

                <br />
            </section>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered

                show={lgShow}
                onHide={() => setLgShow(false)}

            >
                <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                </Modal.Header>
                <Modal.Body >
                    <h4 className="text-center warrantytitle">Warranty Policy</h4>
                    <div className="p-3">
                        <ul className="warrantylist">
                            <li>We value our customers the most and delivering the products without any hassle is our first and foremost priority. We ship the products and dispatch as soon as your order is confirmed. However, it is not possible if a product is out of stock or doesn’t match the availability.</li>
                            <li>Before placing an order, please ensure that the area code or pin code is accessible for our product delivery.</li>
                            <li>In case the pin code is not accessible, you may check with any alternative pin code that can be convenient for you.</li>
                            <li>We will deliver the product to the address mentioned in the time of purchasing.</li>
                            <li>After receipt of payment confirmation, we will ship the product.</li>
                            <li>We offer free shipping to every available pin codes in India. However, the buyers need to pay for any extra tax & Octroi when and where it is applicable.</li>
                            <li>For any other queries regarding the Shipping Policy, you may contact us at <NavLink to="mailto:contact@colorbot.com" className="emaillink">contact@colorbot.com.</NavLink></li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>








        </>
    );
}
export default RegisterWarranty;