import "./productknoxx_quad.css"
import { NavLink, useNavigate } from "react-router-dom";

import axios from 'axios';
import Slider from 'react-slick';
import React, { useState, useEffect } from "react";
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from "moment";
import { Helmet } from 'react-helmet';


const KnoxxquadHead = () => {

    const [metaData, setMetaData] = useState({
        urlSlug: '',
        titleTag: '',
        metaDescription: '',
        markupName: '',
        jsonLdMarkup: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
    });

    const fetchSeoData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/seo-settings/seo003`);
            if (response.ok) {
                const result = await response.json();
                const data = result.data;
                setMetaData({
                    urlSlug: data.urlSlug || '',
                    titleTag: data.titleTag || '',
                    metaDescription: data.metaDescription || '',
                    markupName: data.markupName || '',
                    jsonLdMarkup: data.jsonLdMarkup || '',
                    ogTitle: data.ogTitle || '',
                    ogDescription: data.ogDescription || '',
                    ogImage: data.ogImage || '',
                });
            }
        } catch (error) {

        }

    };

    useEffect(() => {
        fetchSeoData();
    }, [])
    return (
        <>
            <Helmet>
                {/* Dynamically setting title, meta, and Open Graph tags */}
                <title>{metaData.titleTag}</title>
                <meta name="description" content={metaData.metaDescription} />
                <link rel="canonical" href={metaData.urlSlug} />
                <meta property="og:title" content={metaData.ogTitle} />
                <meta property="og:site_name" content="Colorbot" />
                <meta property="og:url" content={metaData.urlSlug} />
                <meta property="og:description" content={metaData.ogDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={metaData.ogImage} />

             
                {/* Dynamically include JSON-LD markup */}
                
                <script type="application/ld+json">
                    {metaData?.jsonLdMarkup ? JSON.parse(metaData?.jsonLdMarkup):""}
                </script>
            </Helmet>

            {/* // <Helmet>

    //     <title>Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot</title>
    //     <meta name="description" content="Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
    //     <link rel="canonical" href="https://colorbot.in/bldc-ceiling-fan" />

    //     <meta property="og:title" content=" Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot" />
    //     <meta property="og:site_name" content="Colorbot" />
    //     <meta property="og:url" content="https://colorbot.in/bldc-ceiling-fan" />
    //     <meta property="og:description" content=" Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
    //     <meta property="og:type" content="website" />
    //     <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon.webp" />
    //     <meta name="twitter:card" content="summary_large_image" />
    //     <meta name="twitter:title" content=" Buy BLDC Ceiling Fans | No.1 Premium BLDC Fans - Colorbot" />
    //     <meta name="twitter:description" content=" Shop an extensive range of BLDC ceiling fans online with remote control. Upgrade your home with premium power saving bldc fans." />
    //     <meta name="twitter:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-banner-coupon.webp" />

    // </Helmet> */}
        </>
    )
};


function Knoxx_Quad(props) {
    const [price, setprice] = useState(null)
    const [discountedprice, setdiscountedprice] = useState(null)
    const [fixedprice, setfixedprice] = useState(null)
    const [fixeddiscountedprice, setfixeddiscountedprice] = useState(null)
    const navigate = useNavigate();
    const [product, setproduct] = useState([])
    const [product_Name, setProduct_Name] = useState("Helix")
    const [product_Description, setProduct_Description] = useState("The Ultimate fun for home, featuring 5-star BLDC motor, inverter-friendly technology, higher air delivery, stylish design and remote. Get longer product protection with a 2+2 years warranty.")
    const [itemName, setItemName] = useState("")
    const [quadData,setQuadData] = useState("")
    const [inventory, setInventory] = useState(0)

    const getproduct = async () => {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ category: "Knoxx-Quad", productfor: "Normal" }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {
                    setproduct(response.data)
                    setprice(response.data[0].ProductPrice)
                    setdiscountedprice(response.data[0].ProductSelling)
                    setfixedprice(response.data[0].ProductPrice)
                    setfixeddiscountedprice(response.data[0].ProductSelling)
                    setProduct_Name(response.data[0].ProductName)
                    setProduct_Description(response.data[0].ProductDescription)
                    setItemName(response.data[0].ItemNo)
                    setInventory(response.data[0].ProductInventory)

                    var set = new Set();
                    var set1 = new Set();
                    for (let i = 0; i < response.data.length; i++) {


                        set.add(response.data[i].ProductSize)




                    }
                    var data = response.data.filter((item) => {
                        return item.ProductSize === response.data[0].ProductSize
                    })
                    for (let i = 0; i < data.length; i++) {
                        set1.add(data[i].ProductColorCode)
                    }
                    var arr = Array.from(set)
                    var arr1 = Array.from(set1)

                    for (let i = 0; i < arr1.length; i++) {
                        if (arr1[i] === "#000000") {
                            colorlist[0].available = true
                        }
                        if (arr1[i] === "#6d4d33") {
                            colorlist[1].available = true
                        }
                        if (arr1[i] === "#535353") {
                            colorlist[2].available = true
                        }
                        if (arr1[i] === "#d8d8d8") {
                            colorlist[3].available = true
                        }
                        if (arr1[i] === "#6d6d6d") {
                            colorlist[4].available = true
                        }
                        if (arr1[i] === "#F0F0F0") {
                            colorlist[5].available = true
                        }
                        if (arr1[i] === "#A47141") {
                            colorlist[6].available = true
                        }
                        if (arr1[i] === "#4A2413") {
                            colorlist[7].available = true
                        }
                        if (arr1[i] === "#F0F0F1") {
                            colorlist[8].available = true
                        }
                        if (arr1[i] === "#8E8E8E") {
                            colorlist[9].available = true
                        }
                    }

                    for (let i = 0; i < buttonlist.length; i++) {
                        buttonlist[i].available = false;  // Set all buttons to false initially
                    }
                    setcolorlist(colorlist)
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] === "900mm") {
                            buttonlist[0].available = true
                        }
                        if (arr[i] === "1200mm") {
                            buttonlist[1].available = true
                        }
                        if (arr[i] === "1400mm") {
                            buttonlist[2].available = true
                        }
                    }
                    setbuttonlist(buttonlist)


                    setbuttonactive(response.data[0].ProductSize)
                    if (localStorage.getItem("activeType") === "Knoxx-Quad") {
                        setactivecolor(localStorage.getItem("activeColor"))
                        setTimeout(() => {
                            document.getElementById(`color-${localStorage.getItem("activeColor")}`).click()
                        }, 300)

                        setShowLoader(false)
                    } else {
                        setactivecolor(response.data[0].ProductColorCode)
                        var imagearr = []
                        imagearr.push(response.data[0].ProductImage)
                        imagearr.push(response.data[0].ProductImage1)
                        imagearr.push(response.data[0].ProductImage2)
                        imagearr.push(response.data[0].ProductImage3)
                        imagearr.push(response.data[0].ProductImage4)
                        imagearr.push(response.data[0].ProductImage5)
                        for (let i = 0; i < 6; i++) {
                            imagearr.push(imagearr[i])
                        }

                        setimage(imagearr)
                        setimageselected(response.data[0].ProductImage)
                        setShowLoader(false)
                    }


                } else {
                    setproduct([])
                    setShowLoader(false)

                }
            })
        } catch (error) {
            console.log(error)
            setShowLoader(false)
        }
    }

    const fetchWebData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotCMSProductCategory/category003`);
            if (response.ok) {
                const result = await response.json();
               setQuadData(result.data.editorContent || "")
            }
        } catch (error) {
            console.log(error)
        }

    };
    const [currentwidth, setcurrentwidth] = useState(window.innerWidth)
    const [buttonlist, setbuttonlist] = useState([
        {
            value: "900mm",
            available: false,

        },
        {
            value: "1200mm",
            available: false,

        }, {
            value: "1400mm",
            available: false,

        }
    ])
    const [imageselected, setimageselected] = useState("")
    const [image, setimage] = useState([])
    const [buttonactive, setbuttonactive] = useState("900mm")
    const [activecolor, setactivecolor] = useState("#000000")
    const [quantity, setquantity] = useState(1)
    const [colorlist, setcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');

    const [state, setState] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        setcurrentwidth(window.innerWidth);
        getproduct()
        fetchWebData()
    }, [])

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    const checkpincode = () => {
        setCity('');
        setState('');
        var value = pincode;

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        arrow: true,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1002,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Colorbot Knoxx-Quad";// Set the title when component mounts
        return () => {
            document.title = "Colorbot Knoxx-Quad"; // Reset the title when component unmounts
        };
    }, []);

    const quadsettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrow: false,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };
    const [remote, setremote] = useState(true)
    const buyproduct = () => {
        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "modal": "Knoxx-Quad",
            "extendedwarranty": true,
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }
        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        if (props.LoggedIn) {

            navigate("/product_buy_now_delivery")
        } else {
            navigate("/product_buy_now_login")
        }

    }
    const addtocart = () => {

        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;

        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "modal": "Knoxx-Quad",
            "extendedwarranty": true,
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }

        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        props.opensidepanel()
    }
    const addToCart = (newItem) => {


        props.setcartdata((prevData) => {
            const existingItemIndex = prevData.findIndex(
                (item) => item.productid === newItem.productid
            );

            if (existingItemIndex !== -1) {
                const updatedData = prevData.map((item, index) => {
                    if (index === existingItemIndex) {
                        // Update the quantity of the existing item
                        const updatedQuantity = parseInt(item.productquantity) + parseInt(newItem.productquantity);
                        const updatedPrice = parseInt(item.productprice) + parseInt(newItem.productprice);
                        const updatedDiscountedPrice = parseInt(item.productdiscountedprice) + parseInt(newItem.productdiscountedprice);
                        return { ...item, productquantity: updatedQuantity, productprice: updatedPrice, productdiscountedprice: updatedDiscountedPrice };
                    }
                    return item;
                });

                localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)

                return updatedData;
            }

            const updatedData = [...prevData, newItem];
            localStorage.setItem('cartdata', JSON.stringify(updatedData));
            window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
            window.localStorage.setItem("addcart", true)
            return updatedData;
        });
    };
    const [activeknoxx, setactiveknoxx] = useState(0)



    const handleChangeQuantity = (newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            // toast.error("Please enter a valid Quantity"); // Display an error toast notification
            setquantity(newQuantity);

        } else {
            setquantity(newQuantity);


        }
    };
    const getActiveColorName = () => {
        const activeColorObject = colorlist.find(color => color.color === activecolor);
        return activeColorObject ? activeColorObject.value : "";
    };

    useEffect(() => {
        getActiveColorName()
    }, [activecolor])

    const getpairedproduct = async (colorcode, productBack = []) => {

        var data = product.filter((item) => {
            return item.ProductColorCode === colorcode && item.ProductSize === buttonactive
        })


        if (data.length > 0) {
            var set = new Set();

            for (let i = 0; i < data.length; i++) {

                if (product[i].ProductColorCode == colorcode) {

                    set.add(product[i].ProductSize)
                }




            }
            var arr = Array.from(set)

            // for (let i = 0; i < buttonlist.length; i++) {
            //     buttonlist[i].available = false;  // Set all buttons to false initially
            // }


            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)

            setbuttonactive(data[0].ProductSize)

            setprice(data[0].ProductPrice)
            setdiscountedprice(data[0].ProductSelling)
            setfixedprice(data[0].ProductPrice)
            setfixeddiscountedprice(data[0].ProductSelling)
            setProduct_Name(data[0].ProductName)
            setProduct_Description(data[0].ProductDescription)
            setItemName(data[0].ItemNo)
            setInventory(data[0].ProductInventory)
            var imagearr = []
            imagearr.push(data[0].ProductImage)
            imagearr.push(data[0].ProductImage1)
            imagearr.push(data[0].ProductImage2)
            imagearr.push(data[0].ProductImage3)
            imagearr.push(data[0].ProductImage4)
            imagearr.push(data[0].ProductImage5)
            for (let i = 0; i < 6; i++) {
                imagearr.push(imagearr[i])
            }

            setimage(imagearr)
            setimageselected(data[0].ProductImage)
            setquantity(1)

        }
    }

    const getsizepairedproduct = async (size) => {
        setbuttonactive(size);
        var data = product.filter((item) => {
            return item.ProductSize === size
        })


        if (data.length > 0) {

            var set = new Set();
            var set1 = new Set();

            for (let i = 0; i < product.length; i++) {

                if (product[i].ProductColorCode == activecolor) {

                    set.add(product[i].ProductSize)
                }




            }

            // setactivecolor(data[0].ProductColorCode)
            for (let i = 0; i < data.length; i++) {




                set1.add(data[i].ProductColorCode)


            }
            for (let i = 0; i < colorlist.length; i++) {
                colorlist[i].available = false;  // Set all buttons to false initially
            }


            var arr1 = Array.from(set1)


            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] === "#000000") {
                    colorlist[0].available = true
                }
                if (arr1[i] === "#6d4d33") {
                    colorlist[1].available = true
                }
                if (arr1[i] === "#535353") {
                    colorlist[2].available = true
                }
                if (arr1[i] === "#d8d8d8") {
                    colorlist[3].available = true
                }
                if (arr1[i] === "#6d6d6d") {
                    colorlist[4].available = true
                }
                if (arr1[i] === "#F0F0F0") {
                    colorlist[5].available = true
                }
                if (arr1[i] === "#A47141") {
                    colorlist[6].available = true
                }
                if (arr1[i] === "#4A2413") {
                    colorlist[7].available = true
                }
                if (arr1[i] === "#F0F0F1") {
                    colorlist[8].available = true
                }
                if (arr1[i] === "#8E8E8E") {
                    colorlist[9].available = true
                }
            }


            setcolorlist(colorlist)

            var arr = Array.from(set)



            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)
            for (let i = 0; i < data.length; i++) {
                if (arr1.includes(activecolor) && data[i].ProductColorCode === activecolor && data[i].ProductSize === size) {

                    setactivecolor(activecolor)
                    setprice(data[i].ProductPrice)
                    setdiscountedprice(data[i].ProductSelling)
                    setfixedprice(data[i].ProductPrice)
                    setfixeddiscountedprice(data[i].ProductSelling)
                    setProduct_Name(data[i].ProductName)
                    setProduct_Description(data[i].ProductDescription)
                    setItemName(data[i].ItemNo)
                    setInventory(data[i].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[i].ProductImage)
                    imagearr.push(data[i].ProductImage1)
                    imagearr.push(data[i].ProductImage2)
                    imagearr.push(data[i].ProductImage3)
                    imagearr.push(data[i].ProductImage4)
                    imagearr.push(data[i].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[i].ProductImage)
                    setquantity(1)


                } else {
                    setactivecolor(arr1[0])
                    setprice(data[0].ProductPrice)
                    setdiscountedprice(data[0].ProductSelling)
                    setfixedprice(data[0].ProductPrice)
                    setfixeddiscountedprice(data[0].ProductSelling)
                    setProduct_Name(data[0].ProductName)
                    setProduct_Description(data[0].ProductDescription)
                    setItemName(data[0].ItemNo)
                    setInventory(data[0].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[0].ProductImage)
                    imagearr.push(data[0].ProductImage1)
                    imagearr.push(data[0].ProductImage2)
                    imagearr.push(data[0].ProductImage3)
                    imagearr.push(data[0].ProductImage4)
                    imagearr.push(data[0].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[0].ProductImage)
                    setquantity(1)
                }
            }

        }
    }

    const ShareInfo = () => {

        if (navigator.share) {
            navigator.share({
                title: "Color Bot",
                text: "ColorBot Knoxx Quad Fan",
                url: window.location.href
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            console.log('Web Share API not supported.');
            // Implement your own custom share functionality here
        }
    };
    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = " " + d + "th" + " " + month[parseInt(m) - 1];

        return time;
    }
    const convertdatewithoutMonth = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = d + "th" + " ";

        return time;
    }


    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif" alt="loader"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :

            <section className="mt-5 pt-4">
                <KnoxxquadHead />
                <div className="shopnow">
                    FREE SHIPPING on all prepaid orders, <span><NavLink to="/bldc-ceiling-fan">SHOP NOW</NavLink></span>
                </div>
                <div className="">
                    <div className="container pt-4 " >
                        <p className="breadcrumb "><a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <a style={{ color: "#000000" }} href="/bldc-ceiling-fan" > BLDC Ceiling Fans </a>   &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1", fontSize: "13px" }} >Colorbot Knoxx Quad</span></p>
                        {product.length > 0 ? <div className="row justify-content-center pt-2">
                            <div className="col-md-12 col-lg-6 pt-2 prod-img-pad">
                                <LazyLoadImage src={imageselected}
                                    width={"100%"}
                                    alt="helixproduct" className="img-fluid darkimage"
                                    effect="blur"
                                />

                                <Slider {...settings} className="helix slick-sliderdark">
                                    {image.map((item, index) => {
                                        return (
                                            <div className="helixmultiple" key={index}>
                                                <LazyLoadImage src={item} onClick={() => {

                                                    setimageselected(item)
                                                }} className={`${imageselected}` === item ? "img-fluid heliximageactive" : "img-fluid"} alt="helixproduct"
                                                    effect="blur" width={"100%"}
                                                />

                                            </div>
                                        )
                                    })}

                                </Slider>
                            </div>
                            <div className="col-md-12 col-lg-6 pt-2">
                                <h1 className="helixproductname producttop">{product_Name}</h1>
                                <p className="helixsubproductname producttop">{product_Description}</p>

                                <span className="helixprice producttop">₹ {discountedprice} <span className="helixmainprice"> {price}</span> <span className="helixdiscount">-{(((price - discountedprice) / price) * 100).toFixed(0)}%</span></span>

                                <p className="line"></p>

                                <div className="helixremoteoption producttop">
                                    <span className="remotename" style={{ marginBottom: "0px" }}>Remote:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <button className={"helixbuttonlist helixbuttonactive helix-remote remote-white"} onClick={() => {
                                        setremote(true)
                                    }} >White</button>
                                    <button className={"helixbuttonlist helixbuttonactive helix-remote remote-black diagonalCross2"} onClick={() => {
                                        setremote(true)
                                    }} >Black</button>
                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename">Sweep Size:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="buttonlist">
                                        {buttonlist.map((item, index) => {

                                            return !item.available ? (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist diagonalCross2"} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    getsizepairedproduct(item.value);


                                                }}>{item.value}</button>

                                            ) : (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist "} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    getsizepairedproduct(item.value);



                                                }}>{item.value}</button>
                                            )
                                        })}

                                    </div>

                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename">Color:</span>
                                    &nbsp;
                                    <span className="helixcolor text-left">{getActiveColorName()}</span>
                                    <br />

                                </div>

                                <div className="helixcolorlist color-list">
                                    {colorlist.map((item, index) => {
                                        return (
                                            <div
                                                className={item.color === activecolor ? "helixcolorlistitem helixcoloractive" : "helixcolorlistitem"}
                                                key={index}
                                                id={`color-${item.color}`}
                                                onClick={() => {
                                                    if (item.available) {
                                                        setactivecolor(item.color);
                                                        getpairedproduct(item.color);
                                                    }
                                                }}
                                                style={{
                                                    transform: "rotate(90deg)",
                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                }}
                                            >
                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>


                                <div className="helixquantity producttop">
                                    <span className="remotename">Quantity:</span>
                                    &nbsp;&nbsp;&nbsp;

                                    <input
                                        type="number"
                                        className="helixselect"
                                        value={quantity}
                                        onChange={(e) => {

                                            const inputValue = parseInt(e.target.value, 10);

                                            if (inputValue < 0 || inputValue > 99) {
                                                // If the input is invalid, you can choose to handle it here.
                                                // For example, you might want to show an error message or prevent further processing.
                                                return;
                                            }
                                            handleChangeQuantity(e.target.value)

                                        }}
                                    />
                                    <span className="helixshare" onClick={() => {
                                        ShareInfo()
                                    }}>Share &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/share.svg" alt="share" className="img-fluid" /> </span>

                                </div>

                                {inventory > 0 && parseInt(quantity) <= parseInt(inventory) ? <div className="helixbutton">
                                    <button id="knoxx-quad-fan-buynow" className={quantity > 0 ? "helixbuynow" : "helixbuynow disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        buyproduct()
                                    }}>Buy Now</button>
                                    <button id="knoxx-quad-fan-addcart" className={quantity > 0 ? "helixaddtocart" : "helixaddtocart disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        addtocart()

                                    }}>Add to Cart</button>
                                </div> : <p className="text-danger text-center">Out of stock!</p>}
                                <div className="helixwarranty">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/2yearwarranty.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="2yearwarranty"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/freeshipping.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="freeshipping"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easyinstallation.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="easyinstallation"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/5star.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="5star"
                                        effect="blur"
                                    />

                                </div>

                                <div className="amazon-flipkart producttop">
                                    <p><b>Also Available on:</b>   <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Amazon.svg" className="img-fluid" alt="amazon" width="73" height="30" /> <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Flipkart.svg" className="img-fluid" alt="flipkart" width="73" height="30" /></p>
                                </div>
                                <div className="pincodechecker">
                                    <div>
                                        <input type="text" className="helixpincode form-control-sm" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => {
                                            setPincode(e.target.value);
                                            setCity('');
                                            setState('');
                                            setError('')
                                        }} />
                                        <button className="helixcheck" onClick={() => {
                                            checkpincode();
                                        }}>Check</button>
                                    </div>
                                    <NavLink to="https://colorbots3.s3.ap-south-1.amazonaws.com/Buyers+Guid/Buyers-Guid-4blade.pdf" target="blank" >  <span className="buyinguide">Fan Buying Guide &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/pdf.svg" className="img-fluid" alt="pdf" width="23" height="23" /></span> </NavLink>                                </div>
                                <div className="Address">
                                    {state !== "" ? <span className="subaddress" >{city} / {state}, {pincode} <br />Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</span> : ""}
                                    <span className="error">{error}</span>
                                </div>
                                <br />
                            </div>
                        </div> : <p className="text-center text-dark m-4">No Products Available</p>}


                    </div>
                    <br />
                    <br />

                    <div className="helixopp knoxx28watt">

                        <video autoPlay muted loop id="helixmyVideo" className="fan-vid" controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/knoxx_quad/knoxx-quad-pc.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/knoxx_quad/knoxx-quad-mobile.mp4"} type="video/mp4" alt="Colorbot Knoxx BLDC fan rotating" />
                        </video>
                        <FadeInSection>
                            <div className="row stellaquadalign knoxx-quad-sec1">

                                <div className="col-md-12 col-lg-6">
                                    <h2 className="text-center stellaquadfirsthead">One more Blade, Much more Comfort</h2>


                                    <p className="stellaquadfirstpara">Introducing Knoxx Quad 4 blade ceiling fan, the ultimate style quotient of home</p>


                                </div>
                            </div>
                        </FadeInSection>
                    </div>

                    <div className="stellaquadcomfort quad-motor pb-5  pt-5 ">

                        <FadeInSection>
                            <div className="container-fluid ">
                                <div className="row ">
                                    <div className="col-md-12 col-lg-6 pt-5 pb-5 quadaligncenter quad-motor-size">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella_quad/stellaquadmotor.png"}

                                            className=" img-fluid w-100 h-100 p-2" alt="BLDC motor"
                                            effect="blur"
                                        />
                                    </div>
                                    <div className="col-md-12 col-lg-6 stellaquadsecond">
                                        <h2 className="helixcomforthead quad-motor-mar">Experience Redefined Efficiency with BLDC</h2>
                                        <br />
                                        <p className="parasub">Featuring next level comfort with:</p>
                                        <br />

                                        <p><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/stella_quad/fan4.png" className="img-fluid" /> Greater air delivery in every corner</p>
                                        <p><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/stella_quad/airflow.png" className="img-fluid" /> 67% Energy saving</p>
                                        <p><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/stella_quad/speedmeter.png" className="img-fluid" /> 3X inverter running time</p>

                                    </div>

                                </div>
                            </div>
                        </FadeInSection>

                    </div>

                    <div className="helixswiper pt-5 prizmasecondtab">
                        <div className="container-fluid pt-5">


                            <FadeInSection>
                                <div className="row justify-content-center  knox-slide-pad">
                                    <div className="col-md-12 col-lg-12">


                                        <Slider {...quadsettings} >
                                            <div className="quadslider">
                                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/knoxx_quad/Knoxx-quad-brown.png"}
                                                    width={window.innerWidth < 840 ? "100%" : "140vh"}
                                                    alt="Colorbot Knoxx Quad BLDC fans in rooms showing the range of colors" className="img-fluid quadsliderimage"
                                                    effect="blur"
                                                />
                                                <div className="quadcolor" style={{ backgroundColor: "#753E2B" }}>
                                                    <h2 className="helixcomforthead knoxx-quad-color">Blanca Brown</h2>
                                                    <br />
                                                    <p className="parasub">“The perfect shade for a modern <br /> ambience”</p>
                                                </div>
                                            </div>

                                            <div className="quadslider">
                                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/knoxx_quad/Knoxx-quad-gray.png"}
                                                    width={window.innerWidth < 840 ? "100%" : "140vh"}
                                                    alt="Colorbot Knoxx Quad BLDC fans in rooms showing the range of colors" className="img-fluid quadsliderimage"
                                                    effect="blur"
                                                />
                                                <div className="quadcolor" style={{ backgroundColor: "#F0F0F0" }}>
                                                    <h2 className="helixcomforthead knoxx-quad-color" style={{ color: "#000000" }}>Noir White</h2>
                                                    <br />
                                                    <p className="parasub" style={{ color: "#000000" }}>“The ultimate color of luxury”</p>
                                                </div>
                                            </div>
                                            <div className="quadslider">
                                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/knoxx_quad/Knoxx-quad-black.png"}
                                                    width={window.innerWidth < 840 ? "100%" : "140vh"}
                                                    alt="Colorbot Knoxx Quad BLDC fans in rooms showing the range of colors" className="img-fluid quadsliderimage"
                                                    effect="blur"
                                                />
                                                <div className="quadcolor" style={{ backgroundColor: "#000000" }}>
                                                    <h2 className="helixcomforthead knoxx-quad-color" >Charcoal Black</h2>
                                                    <br />
                                                    <p className="parasub">“When elegance speaks for itself”</p>
                                                </div>
                                            </div>




                                        </Slider>




                                    </div>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>

                    <div className="stellaquadcomfort3  pt-5 ">

                        <FadeInSection>
                            <div className="container-fluid ">
                                <div className="row justify-content-center">

                                    <div className="col-md-12 col-lg-6 pb-5  stellaquadsecond3">
                                        <h2 className="helixcomforthead ">Enjoy Comfort in Peace</h2>
                                        <br />
                                        <p className="parasub">Colorbot Knoxx Quad’s whisper-quiet operation lets your surroundings filled with air, not with noise. Get uninterrupted breeze without any noisy whirrs.</p>
                                        <br />



                                    </div>

                                </div>
                            </div>
                        </FadeInSection>

                    </div>

                    <div className="stellaquadcomfort stella-quad-remote pb-5  pt-5 " style={{ background: "white" }}>

                        <FadeInSection>
                            <div className="container-fluid  pb-5">
                                <div className="row pb-5">
                                    <div className="col-md-12 col-lg-6 pt-5 quadaligncenter">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella_quad/Stella-quad-remote.png"}

                                            className=" img-fluid  p-2" alt="An image of Colorbot Fan Remote"
                                            effect="blur"
                                        />
                                    </div>
                                    <div className="col-md-12 col-lg-6 stellaquadsecond stela-remote">
                                        <h2 className="helixcomforthead " style={{ color: "black" }}>Convenient Control with Remote</h2>
                                        <br />
                                        <p className="parasub" style={{ color: "black" }}>Now take the complete control of your airflow and adjust the breeze according to your requirements as Colorbot Knoxx 4 blade ceiling fan with remote offers 6 speed options and boost mode.</p>




                                    </div>

                                </div>
                            </div>
                        </FadeInSection>

                    </div>

                    <div style={{ backgroundColor: "#F5F7FA", padding: "30px 0" }}>
                        <div className="container">

                            <FadeInSection>

                                <div className="row mb-5">
                                    <div className="col-md-12">
                                        <span dangerouslySetInnerHTML={{ __html: quadData }}></span>
                                        {/* <h2 className="cate-blw-cont-h2"> Buy 4 Blade ceiling fan online at best price in India</h2>
                                        <p className="cate-blw-cont">Ready to elevate your home cooling game?</p>
                                        <p className="cate-blw-cont">Introducing the Colorbot Knoxx Quad 4 blade ceiling fan, a perfect breeze booster for your home. Upgrade your space with this great performer that brings a seamless balance of functionality, style, and efficiency to your home. </p>
                                        <p className="cate-blw-cont">The Knoxx Quad is a <NavLink to="/bldc-ceiling-fan">BLDC ceiling fan</NavLink> BLDC ceiling fan that boasts high performance with maximum energy efficiency. With its brushless motor and 1200 mm sweep size, this fan delivers greater air in every corner of the room while operating quietly. </p>
                                        <p className="cate-blw-cont">This 4 blade fan is a stylish secret that your home has been missing till now and this is what its decorative appeal says.  </p>
                                        <p className="cate-blw-cont">The Colorbot Knoxx Quad offers you both practicality and aesthetic appeal, meeting all your requirements. Read more to know the reasons why you should buy this Colorbot 4 blade BLDC fan online for your home. </p>


                                        <h2 className="cate-blw-cont-h2">Benefits of Buying Colorbot 4 Blade BLDC ceiling fan</h2>
                                        <p className="cate-blw-cont">The Colorbot 4 blade BLDC fan is a stunning statement piece with a modern cooling solution for your home. Let’s know how this fan benefits you and why this is a smart choice for your home.</p>

                                        <h3 className="cate-blw-cont-h3">Power Efficient: </h3>
                                        <p className="cate-blw-cont"> Features an energy-efficient BLDC motor and higher BEE star ratings, the Knoxx Quad 4 blade ceiling fan comes at the front as a power saver, consuming minimal energy while providing maximum airflow. This fan takes only 28 watts at the speed of 5 and saves around 67% of energy.</p>

                                        <h3 className="cate-blw-cont-h3">Noiseless Spinning: </h3>
                                        <p className="cate-blw-cont"> Over time a normal ceiling fan may create noise. However, the Colorbot Knoxx Quad BLDC fan is designed to operate quietly. With its brushless motor and fewer moving parts, this fan ensures noiseless and smoother operation even at the highest speed.</p>
                                        <p className="cate-blw-cont"> Whether you want to take a nap, watch a movie, or have a conversation in peace, this fan provides an uninterrupted breeze, allowing you to fully enjoy your moments. </p>

                                        <h3 className="cate-blw-cont-h3">Greater Airflow: </h3>
                                        <p className="cate-blw-cont"> This Knoxx Quad 1200 mm 4 blade fan is designed to provide greater and consistent airflow. Its well-balanced 4 aerodynamic ABS blades circulate more air evenly while covering a wider area and ensure stable operation all year long without wobbling. </p>

                                        <h3 className="cate-blw-cont-h3">Stunning Design: </h3>
                                        <p className="cate-blw-cont">By offering a captivating modern style, this Colorbot 4 blade designer ceiling fan absolutely adds a stunning appearance to your living space. </p>
                                        <p className="cate-blw-cont">This Knoxx Quad has a classy semi-matte finish look and comes in different attractive color combinations, effortlessly blending into any interior and complementing the overall aesthetic. </p>

                                        <h3 className="cate-blw-cont-h3">Compact Motor: </h3>
                                        <p className="cate-blw-cont">This Colorbot Knoxx Quad fan boasts a compact motor with polyhedral pattern and a golden ring around it that enhances its overall style making it a focal point in any space. </p>
                                        <p className="cate-blw-cont">This ultimate creativity in this fan makes it perfect for your living room and gives you a premium feel throughout.</p>

                                        <h3 className="cate-blw-cont-h3">LED Speed Indicator: </h3>
                                        <p className="cate-blw-cont">This fan continuously levels up in style. Now its speed indicator LED light which is red and blue in color paired, with black acrylic elevates its aesthetic appearance. </p>

                                        <h3 className="cate-blw-cont-h3">Designed for Longevity: </h3>
                                        <p className="cate-blw-cont">The Knoxx Quad is built to last. Engineered with high-quality 100% copper winding motor, this 4 blade ceiling fan ensures stable and durable performance. Since there is no friction in the motor, it prevents the fan from wear and tear. As a result, it needs less maintenance and gives you trouble-free cooling throughout the year.  </p>

                                        <h3 className="cate-blw-cont-h3">Wide Voltage Range: </h3>
                                        <p className="cate-blw-cont">The Knoxx Quad is not only for saving energy and styling, in fact, it does more than that. This fan is as highly functional at low voltage as it is at constant voltage.   </p>
                                        <p className="cate-blw-cont">With its powerful DC motor, this fan is able to perform efficiently even during voltage fluctuation. Moreover, it can run up to three times longer on the inverter compared to normal fans  </p>

                                        <h3 className="cate-blw-cont-h3">Effortless Control: </h3>
                                        <p className="cate-blw-cont"> Now no more need to get up from your sweet sleep to switch off the fan at midnight as the Colorbot Knoxx Quad 4 blade ceiling fan with remote ease this process. This fan gives you complete control at your fingertips by offering a handy remote.  </p>
                                        <p className="cate-blw-cont"> From adjusting speed up to 6 and turning on/off the fan to timer settings and reverse mode, this fan allows you to customise your cooling comfort with just a click on the remote.   </p>
                                       
                                        <p className="cate-blw-cont"> Whether it is superior performance, maximum savings or visual appeal, the Colorbot Knoxx Quad never misses the mark on satisfaction and guarantees to impress you every time.  </p>
                                        <p className="cate-blw-cont"> Despite featuring BLDC technology and a range of standout features, this fan comes at such low cost. With its impressive 4 blade BLDC fan price, the Knoxx Quad is a value-packed solution for your cooling needs.  </p>
                                        <p className="cate-blw-cont"> Don’t wait any longer- buy this 4 blade BLDC ceiling fan online today at an amazing deal and give your home a stunning makeover. </p>


                                        <h2 className="cate-blw-cont-h2">Frequently Asked Questions</h2>
                                     
                                        <h3 className="cate-blw-cont-h3">1: What is the difference between a 3 blade and 4 blade ceiling fan </h3>
                                        <p className="cate-blw-cont"> The major difference between these two fans is their number of blades. Both fans provide exceptional airflow and styling, but the 4 blade fan offers enhanced stable and balanced air. The 3 blade fan is suitable for smaller rooms and a fan with 4 blades is for larger rooms.</p>

                                        <h3 className="cate-blw-cont-h3">2: Is the Colorbot Knoxx Quad 4 blade BLDC fan energy efficient?</h3>
                                        <p className="cate-blw-cont"> Yes, this Knoxx Quad fan uses an energy-efficient BLDC motor that enables it to consume less energy during the operation. </p>

                                        <h3 className="cate-blw-cont-h3">3: Does a 4 blade ceiling fan deliver more air?</h3>
                                        <p className="cate-blw-cont"> Yes, a ceiling fan with 4 blades delivers more air while covering the wider area. </p>

                                        <h3 className="cate-blw-cont-h3">4: Is the installation process of a 4 blade ceiling fan different from 3 blade fan?</h3>
                                        <p className="cate-blw-cont"> No. The installation process of the 4 blade fan is the same as the 3 blade fan. You only need to attach one more blade in the process. </p>

                                        <h3 className="cate-blw-cont-h3">5: Does a 4 blade fan create noise at the highest speed?</h3>
                                        <p className="cate-blw-cont"> No. However, the fan with 4 blades operates more quietly. The additional blade in the fan helps to distribute the air evenly, which reduces noise.  </p>


 */}

                                    </div>
                                </div>

                            </FadeInSection>
                        </div>
                    </div>





                </div>
            </section>

    )
}
export default Knoxx_Quad;