import { NavLink } from "react-router-dom";
import "./Blog.css";
import { useState, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';
import moment from "moment";

const BlogHead = () => (

    <Helmet>
        <title>Blogs</title>
        <meta name="description" content="Explore insight on Colorbot ceiling fans, latest technology, sustainability, and more. Take updates on upcoming fan models through these blogs." />
        <link rel="canonical" href="https://colorbot.in/blog" />

        <meta property="og:title" content="Blogs" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/blog" />
        <meta property="og:description" content="Explore insight on Colorbot ceiling fans, latest technology, sustainability, and more. Take updates on upcoming fan models through these blogs." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Blog-banner.jpg" />
    </Helmet>
);

function Blog() {
    const [currentPage, setCurrentPage] = useState(1);
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        document.title = "Blogs - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Blogs - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const fetchBlogData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetAllBlog`);
            if (response.ok) {
                const result = await response.json();
                setBlogs(result);
            }
        } catch (error) {
            console.log(error)
        }

    };

    useEffect(() => {
        fetchBlogData();
    }, []);
    return (
        <section className="mt-5 pt-4">
            <BlogHead />
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Blog-banner.jpg"}
                            width={"100vw"}
                            className="d-block w-100 img-fluid" alt="Blogs"
                            effect="blur"
                        />

                    </div>

                </div>

            </div>
            <div className="container-fluid ">
                <br />
                <p className="blogbreadcrumb "><a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1" }}>Blog</span></p>

                {/* <div className="commingsoon-txt" style={{ color: "#000000", fontSize:"30px", margin:"30px 0", textAlign:"center"}}>COMING SOON</div> */}

                <div className="card-group mt-2">
                    {blogs.filter((item)=>item.PostType === "publish").length > 0 && blogs.filter((item)=>item.PostType === "publish").map((blog, index) => (
                        <div className="card m-3" key={index}>
                            <div className="pro-imgblog card-img-top">
                                <LazyLoadImage src={blog.FeaturedImage}
                                    width={"100%"}
                                    className=" img-fluid lazysupportimg" alt="..."
                                    effect="blur"
                                />
                            </div>

                            <div className="card-body">
                                <p className="card-date">{moment(blog.createdAt).format("MMM DD, YYYY")}</p>
                                <br />
                                <h5 className="card-title">{blog.Title}</h5>
                                <span dangerouslySetInnerHTML={{ __html: blog.PostContent?.toString().slice(0,251) }}></span>
                                <NavLink to={`/blog_desc/${blog.blogId}`} style={{ textDecoration: "none" }}><p className="view_text">View More &nbsp;<i className="fa fa-angle-right"></i></p></NavLink>
                            </div>
                        </div>
                    ))}


                  </div>
                <br />
                <br />
                <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 && "disabled"}`}>
                            <button
                                className="page-link prevlink"
                                disabled={currentPage === 1}
                                onClick={() => handleClick(currentPage - 1)}
                            >
                                <i className="fa fa-long-arrow-left" aria-hidden="true"></i> &nbsp;    Prev
                            </button>
                        </li>
                        <li className={`page-item ${currentPage === 1 && "active"}`}>
                            <button
                                className="page-link"
                                onClick={() => handleClick(1)}
                            >
                                1
                            </button>
                        </li>
                        {/* <li className={`page-item ${currentPage === 2 && "active"}`}>
                            <button
                                className="page-link"
                                onClick={() => handleClick(2)}
                            >
                                2
                            </button>
                        </li>
                        <li className={`page-item ${currentPage === 3 && "active"}`}>
                            <button
                                className="page-link"
                                onClick={() => handleClick(3)}
                            >
                                3
                            </button>
                        </li> */}
                        <li className={`page-item ${currentPage === 3 && "disabled"}`}>
                            <button
                                className="page-link prevlink"
                                disabled={currentPage === 3}
                                onClick={() => handleClick(currentPage + 1)}
                            >
                                Next&nbsp; <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
                <br />
                <br />
            </div>
        </section>
    );
}
export default Blog;