import "./productknoxx.css"
import { NavLink, useNavigate } from "react-router-dom";

import axios from 'axios';
import Slider from 'react-slick';
import React, { useState, useEffect } from "react";
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from "moment";
import { Helmet } from 'react-helmet';


const KnoxxHead = () => (

    <Helmet>
        <title>Buy Colorbot Knoxx Remote Control Ceiling Fan Online in India</title>
        <meta name="description" content="Buy Colorbot Knoxx remote control fan engineered with advanced BLDC technology. Enhance your home comfort by customising breeze settings with remote control." />
        <link rel="canonical" href="https://colorbot.in/colorbot-knoxx" />

        <meta property="og:title" content="Buy Colorbot Knoxx Remote Control Ceiling Fan Online in India" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/colorbot-knoxx" />
        <meta property="og:description" content="Buy Colorbot Knoxx remote control fan engineered with advanced BLDC technology. Enhance your home comfort by customising breeze settings with remote control." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://backend.colorbot.in/product/Knoxx/FKWW1200/Knoxx-product1.png" />
    </Helmet>
);


function Knoxx(props) {
    const [price, setprice] = useState(null)
    const [discountedprice, setdiscountedprice] = useState(null)
    const [fixedprice, setfixedprice] = useState(null)
    const [fixeddiscountedprice, setfixeddiscountedprice] = useState(null)
    const navigate = useNavigate();
    const [product, setproduct] = useState([])
    const [product_Name, setProduct_Name] = useState("Helix")
    const [product_Description, setProduct_Description] = useState("The Ultimate fun for home, featuring 5-star BLDC motor, inverter-friendly technology, higher air delivery, stylish design and remote. Get longer product protection with a 2+2 years warranty.")
    const [itemName, setItemName] = useState("")

    const [inventory, setInventory] = useState(0)

    const getproduct = async () => {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ category: "Knoxx", productfor: "Normal" }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {
                    setproduct(response.data)
                    setprice(response.data[2].ProductPrice)
                    setdiscountedprice(response.data[2].ProductSelling)
                    setfixedprice(response.data[2].ProductPrice)
                    setfixeddiscountedprice(response.data[2].ProductSelling)
                    setProduct_Name(response.data[2].ProductName)
                    setProduct_Description(response.data[2].ProductDescription)
                    setItemName(response.data[2].ItemNo)
                    setInventory(response.data[2].ProductInventory)

                    var set = new Set();
                    var set1 = new Set();
                    for (let i = 0; i < response.data.length; i++) {


                        set.add(response.data[i].ProductSize)




                    }
                    var data = response.data.filter((item) => {
                        return item.ProductSize === response.data[2].ProductSize
                    })
                    for (let i = 0; i < data.length; i++) {
                        set1.add(data[i].ProductColorCode)
                    }
                    var arr = Array.from(set)
                    var arr1 = Array.from(set1)

                    for (let i = 0; i < arr1.length; i++) {
                        if (arr1[i] === "#000000") {
                            colorlist[0].available = true
                        }
                        if (arr1[i] === "#6d4d33") {
                            colorlist[1].available = true
                        }
                        if (arr1[i] === "#535353") {
                            colorlist[2].available = true
                        }
                        if (arr1[i] === "#d8d8d8") {
                            colorlist[3].available = true
                        }
                        if (arr1[i] === "#6d6d6d") {
                            colorlist[4].available = true
                        }
                        if (arr1[i] === "#F0F0F0") {
                            colorlist[5].available = true
                        }
                        if (arr1[i] === "#A47141") {
                            colorlist[6].available = true
                        }
                        if (arr1[i] === "#4A2413") {
                            colorlist[7].available = true
                        }
                        if (arr1[i] === "#F0F0F1") {
                            colorlist[8].available = true
                        }
                        if (arr1[i] === "#8E8E8E") {
                            colorlist[9].available = true
                        }
                    }

                    for (let i = 0; i < buttonlist.length; i++) {
                        buttonlist[i].available = false;  // Set all buttons to false initially
                    }
                    setcolorlist(colorlist)
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] === "900mm") {
                            buttonlist[0].available = true
                        }
                        if (arr[i] === "1200mm") {
                            buttonlist[1].available = true
                        }
                        if (arr[i] === "1400mm") {
                            buttonlist[2].available = true
                        }
                    }
                    setbuttonlist(buttonlist)


                    setbuttonactive(response.data[2].ProductSize)
                    if (localStorage.getItem("activeType") === "Knoxx") {
                        setactivecolor(localStorage.getItem("activeColor"))
                        setTimeout(() => {
                            document.getElementById(`color-${localStorage.getItem("activeColor")}`).click()
                        }, 300)
                        setShowLoader(false)

                    } else {
                        setactivecolor(response.data[2].ProductColorCode)
                        var imagearr = []
                        imagearr.push(response.data[2].ProductImage)
                        imagearr.push(response.data[2].ProductImage1)
                        imagearr.push(response.data[2].ProductImage2)
                        imagearr.push(response.data[2].ProductImage3)
                        imagearr.push(response.data[2].ProductImage4)
                        imagearr.push(response.data[2].ProductImage5)
                        for (let i = 0; i < 6; i++) {
                            imagearr.push(imagearr[i])
                        }

                        setimage(imagearr)
                        setimageselected(response.data[2].ProductImage)
                        setShowLoader(false)
                    }



                } else {
                    setproduct([])
                    setShowLoader(false)


                }
            })
        } catch (error) {
            console.log(error)
            setShowLoader(false)
        }
    }
    const [currentwidth, setcurrentwidth] = useState(window.innerWidth)
    const [buttonlist, setbuttonlist] = useState([
        {
            value: "900mm",
            available: false,

        },
        {
            value: "1200mm",
            available: false,

        }, {
            value: "1400mm",
            available: false,

        }
    ])
    const [imageselected, setimageselected] = useState("")
    const [image, setimage] = useState([])
    const [buttonactive, setbuttonactive] = useState("900mm")
    const [activecolor, setactivecolor] = useState("#000000")
    const [quantity, setquantity] = useState(1)
    const [colorlist, setcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');

    const [state, setState] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        setcurrentwidth(window.innerWidth);
        getproduct()
    }, [])

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    const checkpincode = () => {
        setCity('');
        setState('');
        var value = pincode;

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        arrow: true,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 758,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1002,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };


    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Colorbot Knoxx"; // Set the title when component mounts
        return () => {
            document.title = "Colorbot Knoxx"; // Reset the title when component unmounts
        };
    }, []);

    const [remote, setremote] = useState(true)
    const buyproduct = () => {
        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "modal": "Knoxx",
            "extendedwarranty": true,
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }
        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        if (props.LoggedIn) {

            navigate("/product_buy_now_delivery")
        } else {
            navigate("/product_buy_now_login")
        }

    }
    const addtocart = () => {

        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "extendedwarranty": true,
            "modal": "Knoxx",
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }

        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        props.opensidepanel()
    }
    const addToCart = (newItem) => {


        props.setcartdata((prevData) => {
            const existingItemIndex = prevData.findIndex(
                (item) => item.productid === newItem.productid
            );

            if (existingItemIndex !== -1) {
                const updatedData = prevData.map((item, index) => {
                    if (index === existingItemIndex) {
                        // Update the quantity of the existing item
                        const updatedQuantity = parseInt(item.productquantity) + parseInt(newItem.productquantity);
                        const updatedPrice = parseInt(item.productprice) + parseInt(newItem.productprice);
                        const updatedDiscountedPrice = parseInt(item.productdiscountedprice) + parseInt(newItem.productdiscountedprice);
                        return { ...item, productquantity: updatedQuantity, productprice: updatedPrice, productdiscountedprice: updatedDiscountedPrice };
                    }
                    return item;
                });

                localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            }

            const updatedData = [...prevData, newItem];
            localStorage.setItem('cartdata', JSON.stringify(updatedData));
            window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
            window.localStorage.setItem("addcart", true)
            return updatedData;
        });
    };
    const [activeknoxx, setactiveknoxx] = useState(0)



    const handleChangeQuantity = (newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            // toast.error("Please enter a valid Quantity"); // Display an error toast notification
            setquantity(newQuantity);

        } else {
            setquantity(newQuantity);


        }
    };
    const getActiveColorName = () => {
        const activeColorObject = colorlist.find(color => color.color === activecolor);
        return activeColorObject ? activeColorObject.value : "";
    };

    useEffect(() => {
        getActiveColorName()
    }, [activecolor])
    const quadsettings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 1,
        arrow: false,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    };

    const getpairedproduct = async (colorcode) => {

        var data = product.filter((item) => {
            return item.ProductColorCode === colorcode && item.ProductSize === buttonactive
        })
        if (data.length > 0) {
            var set = new Set();

            for (let i = 0; i < data.length; i++) {

                if (product[i].ProductColorCode == colorcode) {

                    set.add(product[i].ProductSize)
                }




            }
            var arr = Array.from(set)

            // for (let i = 0; i < buttonlist.length; i++) {
            //     buttonlist[i].available = false;  // Set all buttons to false initially
            // }


            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)

            setbuttonactive(data[0].ProductSize)

            setprice(data[0].ProductPrice)
            setdiscountedprice(data[0].ProductSelling)
            setfixedprice(data[0].ProductPrice)
            setfixeddiscountedprice(data[0].ProductSelling)
            setProduct_Name(data[0].ProductName)
            setProduct_Description(data[0].ProductDescription)
            setItemName(data[0].ItemNo)
            setInventory(data[0].ProductInventory)
            var imagearr = []
            imagearr.push(data[0].ProductImage)
            imagearr.push(data[0].ProductImage1)
            imagearr.push(data[0].ProductImage2)
            imagearr.push(data[0].ProductImage3)
            imagearr.push(data[0].ProductImage4)
            imagearr.push(data[0].ProductImage5)
            for (let i = 0; i < 6; i++) {
                imagearr.push(imagearr[i])
            }

            setimage(imagearr)
            setimageselected(data[0].ProductImage)
            setquantity(1)

        }
    }

    const getsizepairedproduct = async (size) => {
        setbuttonactive(size);
        var data = product.filter((item) => {
            return item.ProductSize === size
        })


        if (data.length > 0) {

            var set = new Set();
            var set1 = new Set();

            for (let i = 0; i < product.length; i++) {

                if (product[i].ProductColorCode == activecolor) {

                    set.add(product[i].ProductSize)
                }




            }

            // setactivecolor(data[0].ProductColorCode)
            for (let i = 0; i < data.length; i++) {




                set1.add(data[i].ProductColorCode)


            }
            for (let i = 0; i < colorlist.length; i++) {
                colorlist[i].available = false;  // Set all buttons to false initially
            }


            var arr1 = Array.from(set1)


            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] === "#000000") {
                    colorlist[0].available = true
                }
                if (arr1[i] === "#6d4d33") {
                    colorlist[1].available = true
                }
                if (arr1[i] === "#535353") {
                    colorlist[2].available = true
                }
                if (arr1[i] === "#d8d8d8") {
                    colorlist[3].available = true
                }
                if (arr1[i] === "#6d6d6d") {
                    colorlist[4].available = true
                }
                if (arr1[i] === "#F0F0F0") {
                    colorlist[5].available = true
                }
                if (arr1[i] === "#A47141") {
                    colorlist[6].available = true
                }
                if (arr1[i] === "#4A2413") {
                    colorlist[7].available = true
                }
                if (arr1[i] === "#F0F0F1") {
                    colorlist[8].available = true
                }
                if (arr1[i] === "#8E8E8E") {
                    colorlist[9].available = true
                }
            }


            setcolorlist(colorlist)

            var arr = Array.from(set)



            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)
            for (let i = 0; i < data.length; i++) {
                if (arr1.includes(activecolor) && data[i].ProductColorCode === activecolor && data[i].ProductSize === size) {

                    setactivecolor(activecolor)
                    setprice(data[i].ProductPrice)
                    setdiscountedprice(data[i].ProductSelling)
                    setfixedprice(data[i].ProductPrice)
                    setfixeddiscountedprice(data[i].ProductSelling)
                    setProduct_Name(data[i].ProductName)
                    setProduct_Description(data[i].ProductDescription)
                    setItemName(data[i].ItemNo)
                    setInventory(data[i].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[i].ProductImage)
                    imagearr.push(data[i].ProductImage1)
                    imagearr.push(data[i].ProductImage2)
                    imagearr.push(data[i].ProductImage3)
                    imagearr.push(data[i].ProductImage4)
                    imagearr.push(data[i].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[i].ProductImage)
                    setquantity(1)


                } else {
                    setactivecolor(arr1[0])
                    setprice(data[0].ProductPrice)
                    setdiscountedprice(data[0].ProductSelling)
                    setfixedprice(data[0].ProductPrice)
                    setfixeddiscountedprice(data[0].ProductSelling)
                    setProduct_Name(data[0].ProductName)
                    setProduct_Description(data[0].ProductDescription)
                    setItemName(data[0].ItemNo)
                    setInventory(data[0].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[0].ProductImage)
                    imagearr.push(data[0].ProductImage1)
                    imagearr.push(data[0].ProductImage2)
                    imagearr.push(data[0].ProductImage3)
                    imagearr.push(data[0].ProductImage4)
                    imagearr.push(data[0].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[0].ProductImage)
                    setquantity(1)
                }
            }

        }
    }

    const ShareInfo = () => {

        if (navigator.share) {
            navigator.share({
                title: "Color Bot",
                text: "ColorBot Knoxx Fan",
                url: window.location.href
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            console.log('Web Share API not supported.');
            // Implement your own custom share functionality here
        }
    };


    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = " " + d + "th" + " " + month[parseInt(m) - 1];

        return time;
    }
    const convertdatewithoutMonth = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = d + "th" + " ";

        return time;
    }

    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :
            <section className="mt-5 pt-4">
                <KnoxxHead />
                <div className="shopnow">
                    FREE SHIPPING on all prepaid orders, <span><NavLink to="/bldc-ceiling-fan">SHOP NOW</NavLink></span>
                </div>
                <div className="">
                    <div className="container pt-4 " >
                        <p className="breadcrumb "><a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <a style={{ color: "#000000" }} href="/bldc-ceiling-fan" > BLDC Ceiling Fans </a>   &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1", fontSize: "13px" }} >Colorbot Knoxx</span></p>
                        {product.length > 0 ? <div className="row justify-content-center pt-2">
                            <div className="col-md-12 col-lg-6 pt-2 prod-img-pad">
                                <LazyLoadImage src={imageselected}
                                    width={"100%"}
                                    alt="helixproduct" className="img-fluid darkimage"
                                    effect="blur"
                                />

                                <Slider {...settings} className="helix slick-sliderdark">
                                    {image.map((item, index) => {
                                        return (
                                            <div className="helixmultiple" key={index}>
                                                <LazyLoadImage src={item} onClick={() => {

                                                    setimageselected(item)
                                                }} className={`${imageselected}` === item ? "img-fluid heliximageactive" : "img-fluid"} alt="helixproduct"
                                                    effect="blur" width={"100%"}
                                                />

                                            </div>
                                        )
                                    })}

                                </Slider>
                            </div>
                            <div className="col-md-12 col-lg-6 pt-2">
                                <h1 className="helixproductname producttop">{product_Name}</h1>
                                <p className="helixsubproductname producttop">{product_Description}</p>

                                <span className="helixprice producttop">₹ {discountedprice} <span className="helixmainprice"> {price}</span> <span className="helixdiscount">-{(((price - discountedprice) / price) * 100).toFixed(0)}%</span></span>

                                <p className="line"></p>

                                <div className="helixremoteoption producttop">
                                    <span className="remotename" style={{ marginBottom: "0px" }}>Remote:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <button className={"helixbuttonlist helixbuttonactive helix-remote remote-white"} onClick={() => {
                                        setremote(true)
                                    }} >White</button>
                                    <button className={"helixbuttonlist helixbuttonactive helix-remote remote-black diagonalCross2"} onClick={() => {
                                        setremote(true)
                                    }} >Black</button>
                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename">Sweep Size:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="buttonlist">
                                        {buttonlist.map((item, index) => {

                                            return !item.available ? (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist diagonalCross2"} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    getsizepairedproduct(item.value);


                                                }}>{item.value}</button>

                                            ) : (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist "} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    getsizepairedproduct(item.value);



                                                }}>{item.value}</button>
                                            )
                                        })}

                                    </div>

                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename">Color:</span>
                                    &nbsp;
                                    <span className="helixcolor text-left">{getActiveColorName()}</span>
                                    <br />


                                </div>

                                <div className="helixcolorlist color-list">
                                    {colorlist.map((item, index) => {
                                        return (

                                            <div
                                                className={item.color === activecolor ? "helixcolorlistitem helixcoloractive" : "helixcolorlistitem"}
                                                key={index}
                                                id={`color-${item.color}`}
                                                onClick={() => {
                                                    if (item.available) {
                                                        setactivecolor(item.color);
                                                        getpairedproduct(item.color);
                                                    }
                                                }}
                                                style={{
                                                    transform: "rotate(90deg)",
                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                }}
                                            >
                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>

                                <div className="helixquantity producttop">
                                    <span className="remotename">Quantity:</span>
                                    &nbsp;&nbsp;&nbsp;

                                    <input
                                        type="number"
                                        className="helixselect"
                                        value={quantity}
                                        onChange={(e) => {

                                            const inputValue = parseInt(e.target.value, 10);

                                            if (inputValue < 0 || inputValue > 99) {
                                                // If the input is invalid, you can choose to handle it here.
                                                // For example, you might want to show an error message or prevent further processing.
                                                return;
                                            }
                                            handleChangeQuantity(e.target.value)

                                        }}
                                    />
                                    <span className="helixshare" onClick={() => {
                                        ShareInfo()
                                    }}>Share &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/share.svg" alt="share" className="img-fluid" /> </span>

                                </div>

                                {inventory > 0 && parseInt(quantity) <= parseInt(inventory) ? <div className="helixbutton">
                                    <button id="knoxx-fan-buynow" className={quantity > 0 ? "helixbuynow" : "helixbuynow disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        buyproduct()
                                    }}>Buy Now</button>
                                    <button id="knoxx-fan-addcart" className={quantity > 0 ? "helixaddtocart" : "helixaddtocart disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        addtocart()

                                    }}>Add to Cart</button>
                                </div> : <p className="text-danger text-center">Out of stock!</p>}
                                <div className="helixwarranty">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/2yearwarranty.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="2yearwarranty"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/freeshipping.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="freeshipping"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easyinstallation.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="easyinstallation"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/5star.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="5star"
                                        effect="blur"
                                    />

                                </div>

                                <div className="amazon-flipkart producttop">
                                    <p><b>Also Available on:</b>   <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Amazon.svg" className="img-fluid" alt="amazon" /> <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Flipkart.svg" className="img-fluid" alt="flipkart" /></p>
                                </div>
                                <div className="pincodechecker">
                                    <div>
                                        <input type="text" className="helixpincode form-control-sm" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => {
                                            setPincode(e.target.value);
                                            setCity('');
                                            setState('');
                                            setError('')
                                        }} />
                                        <button className="helixcheck" onClick={() => {
                                            checkpincode();
                                        }}>Check</button>
                                    </div>
                                    <NavLink to="https://colorbots3.s3.ap-south-1.amazonaws.com/Buyers+Guid/Buyers-Guid.pdf" target="blank" >  <span className="buyinguide">Fan Buying Guide &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/pdf.svg" className="img-fluid" alt="pdf" /></span> </NavLink></div>
                                <div className="Address">
                                    {state !== "" ? <span className="subaddress" >{city} / {state}, {pincode} <br />Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</span> : ""}
                                    <span className="error">{error}</span>
                                </div>
                                <br />
                            </div>
                        </div> : <p className="text-center text-dark m-4">No Products Available</p>}


                    </div>
                    <div className="imagecollarge ">
                        <div className="container pt-5 pb-5">
                            <div className="imagehorizontal subimagehorizontal">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-tiles-img1.jpg"}

                                    className=" img-fluid helix100 p-2" alt="BLDC chip"
                                    effect="blur"
                                />

                                <div>
                                    <div className="subimagehorizontal">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-tiles-img2.jpg"}

                                            className=" img-fluid helix100 p-2 " alt="a Colorbot  Knoxx ceiling fan showing its energy consumption of only 28 watts "
                                            effect="blur"
                                        />
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-tiles-img3.jpg"}

                                            className=" img-fluid helix100 p-2" alt="BLDC motor"
                                            effect="blur"
                                        />


                                    </div>
                                    <div className="subimagehorizontal">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-tiles-img4.jpg"}

                                            className=" img-fluid helix100 p-2" alt="Colorbot Knoxx ceiling fan represents bi-directional rotation"
                                            effect="blur"
                                        />
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-tiles-img5.jpg"}

                                            className=" img-fluid helix100 p-2" alt="Colorbot Knoxx fans show range of colours"
                                            effect="blur"
                                        />


                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                    <div className="helixopp knoxx28watt">

                        <video autoPlay muted loop id="helixmyVideo" className="fan-vid knoxx-fan28" controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/28watts-pc.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/28watts-mobile.mp4"} type="video/mp4" alt="A video of Knoxx fan representing low power consumption that’s just 28 watts" />
                        </video>
                        <FadeInSection>
                            <div className="row">
                                <div className="col-md-6 col-lg-7">

                                </div>
                                <div className="col-md-6 col-lg-4  knoxx28watt-txt  helixcontentright ">
                                    <h2>Unwind Efficiency</h2>
                                    <br />

                                    <p>Get more done with less power consumption. Get better cooling without draining energy with the upgraded power-saving technology. Enjoy energy efficiency with the new Colorbot Knoxx remote control fan that takes up only ~28 watts even at the maximum speed.</p>


                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    <div className="container knoxxopp knox-motor mt-5 mb-5">


                        <FadeInSection>
                            <div className="row">

                                <div className="col-md-12  col-lg-5 helixcontentleft ">
                                    <h2>Innovation Inside</h2>
                                    <br />
                                    <p>Colorbot Knoxx takes air circulation to the next level with the superior BLDC motor.
                                        This advanced and powerful technology lets your fan run seamlessly while saving on every spin.</p>
                                    <br />
                                    <p><b><i>Seamless performance</i></b></p>
                                    <p className="knoxx-motor">Runs at voltage range of  <b>165V-285V</b></p>
                                    <p><b><i>Inverter friendly operation</i></b></p>
                                    <p className="knoxx-motor"><b>3X</b> running time on a single inverter battery</p>
                                    <p><b><i>Efficient</i></b></p>
                                    <p className="knoxx-motor"><b>3X</b> Power Saving</p>
                                    <p><b><i>Powerful</i></b></p>
                                    <p className="knoxx-motor"> <b>360</b> RPM at Maximum speed</p>
                                </div>
                                <div className=" col-lg-1"></div>
                                <div className="col-md-12 col-lg-6 ">
                                    <video autoPlay muted loop id="helixmyVideo" className="knoxxmotor" controls={false} playsInline>
                                        <source src="https://colorbots3.s3.ap-south-1.amazonaws.com/motor.mp4" type="video/mp4" alt="A video of Knoxx BLDC fan motor opening" />
                                    </video>
                                </div>

                            </div>
                        </FadeInSection>

                    </div>
                    <div className="knoxxbluechip knoxxblue">


                        {/* <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loop 1.svg" className="img-fluid helixmyVideo1"  alt="loop" /> */}
                        <FadeInSection>
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/chip-blue.png" className="img-fluid blue-chip-knoxx " alt="BLDC chip, denoting advanced BLDC technology for upgraded comfort." />
                                </div>
                                <div className=" col-lg-2 "></div>
                                <div className="col-md-12 col-lg-4 helixcontentright comfort-chip " >
                                    <h2>Comfort Upgraded</h2>
                                    <br />
                                    <p>Say goodbye to out dated, energy-guzzling fans and embrace the future of smart cooling. Programmed with Color-B algorithm, the smart chip intelligently adjusts speed and power consumption, making Colorbot Knoxx the intelligent fan.</p>

                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    {currentwidth < 758 ? <div className="helixopp knoxx28watt">


                        <FadeInSection>
                            <div className="row">

                                <div className="col-md-9 helixcontentleft knoxx-fan-flow quad-motor-mar">
                                    <h2 className="helixcomforthead ">Unparalleled Performance</h2>


                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                All-season Comfort
                                            </h3>
                                            <p className="subhelixcomfortpara">

                                                Colorbot Knoxx fan keeps you comfortable and cozy  throughout the year with its bi-directional
                                                airflow and high-speed air delivery.

                                            </p>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                Perfect Airflow

                                            </h3>
                                            <p className="subhelixcomfortpara">
                                                The fan is engineered to make every corner well ventilated by delivering 230 CMM Airflow.
                                            </p>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                Maximum Speed

                                            </h3>
                                            <p className="subhelixcomfortpara">
                                                360 RPM at maximum speed, enough to blow away your summer heat!
                                            </p>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </FadeInSection>
                        <video autoPlay muted loop id="helixmyVideo" className="fan-vid" controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-airflow-fan.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-airflow-fan-mobile.mp4"} type="video/mp4" alt="Knoxx BLDC fan’s greater airflow, speed" />
                        </video>
                    </div> :
                        <div className="knoxxcomfort pb-5 pt-5 ">
                            <video autoPlay muted loop id="helixmyVideo" className="knoxx-airflow-vid" style={{ right: 0 }} controls={false} playsInline>
                                <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-airflow-fan.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-airflow-fan-mobile.mp4"} type="video/mp4" alt="Knoxx BLDC fan’s greater airflow, speed" />
                                {/* <source src="https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-airflow-fan.mp4" type="video/mp4" /> */}
                            </video>
                            <br />
                            {/* <br/>
<br/> */}
                            <FadeInSection>
                                <div className="container ">
                                    <div className="row">
                                        <div className="col-md-9 helixcontentleft knoxx-fan-flow ">
                                            <h2 className="helixcomforthead">Unparalleled Performance</h2>


                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h3 className="subhelixcomforthead">
                                                        All-season Comfort
                                                    </h3>
                                                    <p className="subhelixcomfortpara">

                                                        Colorbot Knoxx fan keeps you comfort and cozy through out the year with its bidirectional
                                                        airflow and high speed air delivery.

                                                    </p>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h3 className="subhelixcomforthead">
                                                        Perfect Airflow

                                                    </h3>
                                                    <p className="subhelixcomfortpara">
                                                        The fan is engineered to make every corner well ventilated by delivering 230 CMM Airflow.
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h3 className="subhelixcomforthead">
                                                        Maximum Speed

                                                    </h3>
                                                    <p className="subhelixcomfortpara">
                                                        360 RPM at maximum speed, enough to blow away your summer heat!
                                                    </p>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </FadeInSection>
                            <br />
                            <br />
                        </div>}
                    <div className="helixswiper knoxx-sweep pb-5 pt-5 knoxxblue">
                        <div className="container-fluid ">
                            <FadeInSection>
                                <div className="row justify-content-center">
                                    <div className="col-md-6 ">

                                        <h2 className="text-center knoxx-color">Classier Colors</h2>


                                        <p className="text-center pt-3">Choose from dust and scratch resistant sophisticated shades of
                                            Colorbot Knoxx that uplifts the look of your living space. Either for your office or for your home, the
                                            choicest range of colorful fans is sure to add elegance and comfort at the same time.</p>
                                    </div>
                                </div>
                            </FadeInSection>
                            <br />
                            <br />
                            {window.innerWidth < 768 ? <FadeInSection>
                                <div className="row justify-content-center ">
                                    <div className="col-md-12 col-lg-12">


                                        <Slider {...quadsettings} >

                                            {[0, 1, 2, 3, 4].map((item, index) => {
                                                return (

                                                    <div className="slider-wrapmobile">
                                                        <div className={`knoxxslide slidemobile${index}`} >
                                                        </div>
                                                    </div>

                                                )


                                            })}










                                        </Slider>




                                    </div>
                                </div>
                            </FadeInSection> :
                                <FadeInSection>
                                    <div className="row justify-content-center">
                                        <div className="col-md-11 col-lg-10">

                                            <div className="slider-wrap">
                                                {[0, 1, 2, 3, 4].map((item, index) => {
                                                    return (
                                                        <div className={activeknoxx === index ? `knoxxslide slide${index} activeslide` : `knoxxslide slide${index}`} key={index} onMouseOver={() => {
                                                            setactiveknoxx(index)
                                                        }} onMouseOut={() => {
                                                            setactiveknoxx(0)
                                                        }}>
                                                        </div>
                                                    )

                                                })}

                                            </div>


                                        </div>
                                    </div>
                                </FadeInSection>}
                        </div>
                    </div>
                    <div className=" helixopp helixkonxx knoxx-remote">



                        <FadeInSection>
                            <div className="row">
                                <div className="col-md-12 col-lg-6 ">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/knoxxremote.png"}

                                        className=" img-fluid helix100 knoxx-remoteimg" alt="Colorbot Fan remote showing its different features"
                                        effect="blur"
                                    />
                                    {/* <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/knoxxremote.svg" className="img-fluid " alt="knoxxremote" /> */}
                                </div>

                                <div className="col-md-12 col-lg-5 helixcontentright" >

                                    <h2>Take Complete Control</h2>
                                    <br />
                                    <p>Control the breeze at your fingertips with remote control operation and stay cool without leaving your seat. Knoxx BLDC remote control ceiling fan lets you personalize your cooling experience.</p>
                                    <br />
                                    <p className="knoxxcenter"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/knoxxrefresh.svg" className="img-fluid knoxximg" alt="refresh" /> &nbsp;Reverse Mode </p>
                                    <p className="knoxxcenter"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/knoxxpoint.svg" className="img-fluid knoxximg" alt="point" /> &nbsp;Point from anywhere  </p>
                                    <p className="knoxxcenter"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/knoxxeye.svg" className="img-fluid knoxximg" alt="eye" /> &nbsp;Sleep mode</p>
                                    <p className="knoxxcenter"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/knoxxmeter.svg" className="img-fluid knoxximg" alt="meter" />&nbsp; Boost mode</p>

                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    <div className=" helixopp knoxx-ecopack">

                        <video autoPlay muted loop id="helixmyVideo" controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/packaging_03.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-knoxx/Knoxx-packaging-mobile.mp4"} type="video/mp4" alt="A video showing eco friendly packaging and materials inside the Colorbot package." />
                        </video>
                        <div className="container">
                            <FadeInSection>
                                <div className="row" style={{ height: "100vh" }}>

                                    <div className="col-md-6 knoxxmiddle helixcontentleft ">
                                        <h2>Eco-friendly Packaging</h2>
                                        <br />
                                        <p>Colorbot always prioritizes sustainability. Our process uses 100% recyclable materials and cutting-edge technology to reduce waste and create a more environmentally friendly product. </p>
                                        <br />

                                        <p className="knoxxapplicable"><i>*Actual product may differ from the image.</i></p>
                                    </div>


                                </div>
                            </FadeInSection>
                        </div>

                    </div>
                </div>
            </section>

    )
}
export default Knoxx;